import * as React from 'react'
import { graphql } from 'gatsby'

export const Partnered = ({ slice }) => {

      return (

            slice.primary.partnered_link && slice.primary.partnered_link.url && 
            <div className="c-footer__col">
                  <h3 className="u-hidden-for-seo">{slice.primary.partnered_title}</h3>
                  <a href={slice.primary.partnered_link.url} target="_blank" class="c-footer__google-link">
                        <img src="../../../assets/images/static/footer/google-partner-white@xs.svg" width={142} height={52} alt="Google Cloud Partner" title="Google Cloud Partner" className="c-footer__google c-footer__google@xs" loading="lazy" />
                        <img src="../../../assets/images/static/footer/google-partner-white@xl.svg" width={136} height={136} alt="Google Cloud Partner" title="Google Cloud Partner" className="c-footer__google c-footer__google@xl" loading="lazy" />
                  </a>
            </div>
      )

}


export const query = graphql`
  fragment PostDataBodyPartnered on PrismicFooterDataBodyPartnered {
      primary {
            partnered_title,
            partnered_link{
                  url
            }
      }
  }
`