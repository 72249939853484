import React from "react"
import PropTypes from "prop-types"
import RichText from "./richText"
import { Link } from "gatsby";


const CallToAction = ({ data }) => {

      return (
            <>
                  {/* CTA */}
                  <div className="c-cta">
                        <div className="c-cta__wrapper o-wrapper">
                              <div className="c-cta__heading u-alt-font">
                                    <RichText render={data.cta_title.richText} />
                              </div> {/* .c-cta__heading */}
                              {data.cta_description && <RichText render={data.cta_description.richText} />}
                              <Link to="/contact" className="c-cta__link c-btn c-btn--icon c-btn--cta c-btn--ih u-tap-size">{data.cta_linktitle}<span className="c-btn__icon"/></Link>
                              
                        </div> {/* .c-cta__wrapper */}
                  </div> {/* .c-cta */}

            </>
      )

}

CallToAction.propTypes = {
      data: PropTypes.object,
}

export default CallToAction