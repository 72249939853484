/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 */
exports.linkResolver = (doc) => {

  switch (doc.type) {
    case 'blog_post': {
      return `/blog/${doc.uid}`
    }
    case 'blog_category': {
      return `/blog/categories/${doc.uid}`
    }
    case 'blog_author': {
      return `/blog/authors/${doc.uid}`
    }
    case 'case': {
      return `/work/${doc.uid}`
    }
    case 'about' : {
      return `${doc.uid}`
    }
    case 'services' : {
      return `${doc.uid}`
    }
    case 'get_in_touch' : {
      return `${doc.uid}`
    }
    case 'work' : {
      return `${doc.uid}`
    }
    case 'blog' : {
      return `${doc.uid}`
    }
    case 'categories' : {
      return `${doc.uid}`
    }
    case 'tags' : {
      return `${doc.uid}`
    }
    case 'authors' : {
      return `${doc.uid}`
    }
    default:
      return `/`
  }
}