import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useLocation } from "@reach/router";


function Seo({ metadata, title, description, keywords, avatar, noindex }) {

  const { pathname } = useLocation();

  const metaTitle = title || metadata.title
  const metaDescription = description || metadata.description
  const metaKeywords = keywords || metadata.keywords
  const metaAvatar = avatar || metadata.avatar.url
  const metaUrl = metadata.url + pathname
  const lang = 'en'
  const meta = [];

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        titleTemplate={title ? `%s | ${metadata.title}` : `${metadata.title}`}
        
        meta={[
          {
            name: `robots`,
            content:  `${noindex ? 'noindex' : 'index,follow' }`,
          },
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: `keywords`,
            content: metaKeywords,
          },
          {
            name: `generator`,
            content: `Bloque - https://bloque.com.uy`,
          },
          {
            name: `author`,
            content: 'Create Thrive',
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:site_name`,
            content: 'Create Thrive',
          },
          {
            property: `og:image:width`,
            content: `1200`,
          },
          {
            property: `og:image:height`,
            content: `630`,
          },
          {
            property: `twitter:image:src`,
            content: metaAvatar,
          },
          {
            property: `og:image`,
            content: metaAvatar,
          },
          {
            property: `og:url`,
            content: metaUrl,
          },

        ].concat(meta)}
      
      >

        <link rel="canonical" href={metaUrl} />

      </Helmet>

    </>
  )

}

Seo.propTypes = {
  metadata: PropTypes.object,
  description: PropTypes.string,
  title: PropTypes.string,
  keywords: PropTypes.string,
  avatar: PropTypes.string
}

export default Seo

