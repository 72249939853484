import React, { useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import { Waypoint } from "react-waypoint";

const Header = ({ socials, headerTransparent, notSeoLogo }) => {
  let $header = null;

  useLayoutEffect(() => {
    $header = document.getElementsByClassName("js-header")[0];
  });

  // headerWaypointFix
  function headerWaypointFix() {
    let $limit = document.querySelector(".c-header__limit");
    let bodyTop = document.body.getBoundingClientRect().top;
    let limitTop = $limit.getBoundingClientRect().top;

    let limitOffset = bodyTop + limitTop;

    if (limitOffset < 0) {
      $header.classList.add("is-fixed");
    }
  }

  useEffect(() => {
    headerWaypointFix();
  }, []);

  const { pathname } = useLocation();

  let isHome = pathname === "/" ? true : false;
  let isContact = pathname.indexOf("/contact") !== -1 ? true : false;
  const splittedPathname = pathname?.split("/");

  let isBlog = splittedPathname[1] === "blog" && splittedPathname.length === 3;

  return (
    <>
      {/* HEADER */}
      <header
        className={`c-header js-header ${
          headerTransparent ? "c-header--transparent" : ""
        }`}
      >
        <div className='c-header__holder'>
          <a
            className='c-header__announcement-bar'
            style={{
              display: isBlog ? "none" : "inline-block",
            }}
            href='https://createthrive.com/blog/very-good-ventures-and-createthrive-are-now-one-team'
            id='announcement-bar'
          >
            🦄 Very Good Ventures and CreateThrive are now One Team! →
          </a>
          <div className='c-header__wrapper o-wrapper o-wrapper--xl'>
            {!notSeoLogo ? (
              <h1 className='c-header__brand'>
                <span className='u-hidden-for-seo'>CreateThrive</span>

                {isHome && (
                  <button
                    className='c-header__brand-link u-tap-size'
                    title='CreateThrive'
                  >
                    <svg
                      className='c-header__logo'
                      width={230}
                      height={55}
                      viewBox='0 0 444 92'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g className='c-logo__iso' fill='#ffffff'>
                        <path d='M0 0V91.47H91.47V0H0ZM32.67 13.07H45.74V26.14H32.67V13.07ZM32.67 39.2H19.6V26.13H32.67V39.2ZM58.8 65.33H45.73V78.4H32.66V65.33H45.73V52.26H58.8V65.33V65.33ZM58.8 39.2H45.73V52.27H32.66V39.2H45.73V26.13H58.8V39.2ZM71.87 52.27H58.8V39.2H71.87V52.27Z' />
                        <path d='M228.57 42.35C227.58 40.47 226.11 39.14 224.18 38.38C222.25 37.61 220.03 37.23 217.54 37.23C214.14 37.23 211.46 37.96 209.49 39.43C207.52 40.89 206.18 42.79 205.47 45.13L211.9 47.17C212.35 45.88 213.14 44.98 214.28 44.48C215.41 43.97 216.5 43.72 217.55 43.72C219.43 43.72 220.77 44.11 221.57 44.9C222.2 45.51 222.57 46.41 222.71 47.57C221.51 47.75 220.37 47.92 219.3 48.09C217.47 48.35 215.83 48.63 214.39 48.93C212.94 49.23 211.7 49.57 210.65 49.95C209.13 50.51 207.92 51.2 207.02 52.04C206.13 52.86 205.49 53.81 205.09 54.89C204.69 55.97 204.49 57.16 204.49 58.47C204.49 60.04 204.85 61.48 205.56 62.78C206.29 64.07 207.36 65.11 208.75 65.89C210.16 66.66 211.88 67.04 213.9 67.04C216.34 67.04 218.35 66.62 219.94 65.79C221.21 65.12 222.4 64.1 223.52 62.78V66.26H229.74V49.01C229.74 47.72 229.69 46.55 229.58 45.51C229.47 44.46 229.14 43.42 228.59 42.37L228.57 42.35ZM221.93 57.66C221.74 58.06 221.37 58.56 220.83 59.15C220.31 59.74 219.59 60.27 218.69 60.74C217.8 61.19 216.7 61.42 215.4 61.42C214.58 61.42 213.87 61.3 213.28 61.05C212.69 60.81 212.23 60.46 211.89 60C211.58 59.53 211.42 58.97 211.42 58.33C211.42 57.86 211.52 57.43 211.71 57.05C211.92 56.67 212.23 56.32 212.65 56C213.08 55.67 213.65 55.36 214.35 55.09C214.96 54.86 215.67 54.65 216.47 54.46C217.27 54.27 218.33 54.06 219.63 53.83C220.47 53.67 221.48 53.49 222.64 53.3C222.62 53.8 222.6 54.35 222.55 54.98C222.5 55.99 222.29 56.88 221.92 57.65L221.93 57.66Z' />
                        <path d='M245.29 30.17H238.18V38.01H233.37V43.5H238.18V54.27C238.18 55.96 238.2 57.48 238.23 58.82C238.26 60.14 238.63 61.45 239.33 62.74C240.1 64.12 241.21 65.12 242.67 65.75C244.15 66.36 245.82 66.68 247.69 66.72C249.57 66.77 251.49 66.62 253.44 66.25V60.34C251.77 60.57 250.24 60.64 248.87 60.55C247.49 60.46 246.49 59.92 245.86 58.93C245.53 58.41 245.35 57.71 245.31 56.84C245.29 55.95 245.28 54.96 245.28 53.86V43.51H253.43V38.02H245.28V30.18L245.29 30.17Z' />
                        <path d='M124.89 36.1C126.49 35.04 128.49 34.51 130.88 34.51C133.49 34.51 135.63 35.15 137.28 36.42C138.93 37.67 140.11 39.44 140.81 41.73L147.97 39.74C146.77 36.08 144.7 33.18 141.75 31.04C138.82 28.9 135.2 27.83 130.88 27.83C127.12 27.83 123.87 28.65 121.13 30.29C118.41 31.93 116.31 34.22 114.83 37.16C113.37 40.1 112.63 43.53 112.63 47.43C112.63 51.33 113.36 54.76 114.83 57.7C116.31 60.64 118.41 62.94 121.13 64.57C123.86 66.21 127.11 67.03 130.88 67.03C135.2 67.03 138.82 65.96 141.75 63.82C144.7 61.68 146.77 58.78 147.97 55.12L140.81 53.13C140.11 55.39 138.94 57.16 137.28 58.44C135.62 59.71 133.49 60.35 130.88 60.35C128.49 60.35 126.5 59.82 124.89 58.76C123.31 57.7 122.11 56.2 121.31 54.27C120.51 52.34 120.11 50.06 120.11 47.45C120.11 44.84 120.51 42.56 121.31 40.63C122.11 38.7 123.31 37.2 124.89 36.14V36.1Z' />
                        <path d='M167.73 37.96C166.88 38.01 166.05 38.16 165.25 38.4C164.45 38.63 163.71 38.95 163.03 39.37C162.14 39.89 161.39 40.55 160.78 41.36C160.49 41.74 160.23 42.15 160 42.57V38.02H153.73V66.24H160.89V51.81C160.89 50.73 161.04 49.76 161.33 48.91C161.63 48.04 162.06 47.29 162.64 46.66C163.22 46.02 163.92 45.5 164.76 45.12C165.6 44.72 166.53 44.48 167.56 44.41C168.6 44.32 169.52 44.41 170.3 44.65V38.01C169.45 37.91 168.59 37.89 167.74 37.96H167.73Z' />
                        <path d='M195 39.32C192.93 37.93 190.41 37.23 187.45 37.23C184.66 37.23 182.2 37.87 180.05 39.14C177.91 40.39 176.23 42.16 175.01 44.44C173.79 46.72 173.18 49.41 173.18 52.49C173.18 55.31 173.8 57.82 175.04 60.02C176.29 62.2 178.02 63.91 180.21 65.17C182.42 66.41 184.97 67.03 187.87 67.03C190.59 67.03 193.09 66.32 195.37 64.89C197.67 63.46 199.35 61.46 200.41 58.88L193.3 56.84C192.78 57.99 192.02 58.87 191.03 59.48C190.04 60.09 188.84 60.39 187.45 60.39C185.24 60.39 183.56 59.68 182.41 58.25C181.58 57.19 181.06 55.81 180.84 54.12H200.65C200.95 50.71 200.6 47.73 199.61 45.21C198.62 42.68 197.08 40.72 195.01 39.33L195 39.32ZM187.76 43.4C189.83 43.4 191.32 44.04 192.23 45.31C192.84 46.13 193.24 47.31 193.45 48.84H181.02C181.3 47.54 181.75 46.46 182.4 45.63C183.55 44.15 185.34 43.41 187.76 43.41V43.4Z' />
                        <path d='M381.19 27.82H374.08V34.09H381.19V27.82Z' />
                        <path d='M381.19 38.01H374.08V66.23H381.19V38.01Z' />
                        <path d='M278.46 39.32C276.39 37.93 273.87 37.23 270.91 37.23C268.12 37.23 265.66 37.87 263.52 39.14C261.38 40.39 259.69 42.16 258.48 44.44C257.26 46.72 256.65 49.41 256.65 52.49C256.65 55.31 257.27 57.82 258.51 60.02C259.76 62.2 261.49 63.91 263.68 65.17C265.89 66.41 268.44 67.03 271.34 67.03C274.06 67.03 276.56 66.32 278.84 64.89C281.14 63.46 282.82 61.46 283.88 58.88L276.77 56.84C276.25 57.99 275.49 58.87 274.5 59.48C273.51 60.09 272.31 60.39 270.92 60.39C268.71 60.39 267.03 59.68 265.88 58.25C265.05 57.19 264.53 55.81 264.31 54.12H284.12C284.42 50.71 284.07 47.73 283.07 45.21C282.08 42.68 280.54 40.72 278.47 39.33L278.46 39.32ZM271.22 43.4C273.29 43.4 274.78 44.04 275.69 45.31C276.3 46.13 276.7 47.31 276.91 48.84H264.48C264.76 47.54 265.21 46.46 265.86 45.63C267.01 44.15 268.79 43.41 271.22 43.41V43.4Z' />
                        <path d='M440.18 54.11H443.6C443.9 50.7 443.55 47.72 442.55 45.2C441.56 42.67 440.02 40.71 437.95 39.32C435.88 37.93 433.36 37.23 430.4 37.23C427.61 37.23 425.15 37.87 423.01 39.14C420.87 40.39 419.19 42.16 417.97 44.44C416.75 46.72 416.14 49.41 416.14 52.49C416.14 55.31 416.76 57.82 418 60.02C419.26 62.2 420.98 63.91 423.17 65.17C425.38 66.41 427.93 67.03 430.83 67.03C433.55 67.03 436.05 66.32 438.33 64.89C440.63 63.46 442.31 61.46 443.37 58.88L436.26 56.84C435.74 57.99 434.98 58.87 433.99 59.48C433 60.09 431.8 60.39 430.41 60.39C428.2 60.39 426.52 59.68 425.37 58.25C424.54 57.19 424.02 55.81 423.8 54.12H440.19L440.18 54.11ZM430.72 43.4C432.79 43.4 434.28 44.04 435.19 45.31C435.8 46.13 436.2 47.31 436.41 48.84H423.98C424.26 47.54 424.71 46.46 425.36 45.63C426.51 44.15 428.29 43.41 430.72 43.41V43.4Z' />
                        <path d='M400.21 57.56L393.52 38.01H386.41L396.65 66.23H403.76L414.01 38.01H406.9L400.21 57.56Z' />
                        <path d='M283.75 35.24H295.62V66.23H302.73V35.24H314.59V28.6H283.75V35.24Z' />
                        <path d='M366.03 37.96C365.18 38.01 364.35 38.16 363.55 38.4C362.75 38.63 362.01 38.95 361.33 39.37C360.44 39.89 359.69 40.55 359.08 41.36C358.79 41.74 358.53 42.15 358.3 42.57V38.02H352.03V66.24H359.19V51.81C359.19 50.73 359.34 49.76 359.63 48.91C359.93 48.04 360.36 47.29 360.94 46.66C361.52 46.02 362.22 45.5 363.06 45.12C363.9 44.72 364.83 44.48 365.86 44.41C366.9 44.32 367.82 44.41 368.6 44.65V38.01C367.75 37.91 366.89 37.89 366.04 37.96H366.03Z' />
                        <path d='M344.53 44.75C344.2 43.5 343.64 42.29 342.86 41.14C342.09 39.99 341.01 39.04 339.62 38.29C338.23 37.54 336.42 37.17 334.21 37.17C331.44 37.17 329.14 37.77 327.31 38.97C326.46 39.54 325.71 40.19 325.06 40.92V28.59H318.74V66.22H325.95V51.9C325.95 50.14 326.14 48.73 326.53 47.67C326.93 46.61 327.44 45.81 328.05 45.27C328.66 44.71 329.31 44.34 330.01 44.15C330.71 43.96 331.36 43.86 331.97 43.86C333.49 43.86 334.65 44.2 335.47 44.88C336.31 45.56 336.91 46.4 337.27 47.41C337.64 48.42 337.85 49.43 337.92 50.44C337.99 51.43 338.02 52.25 338.02 52.9V66.23H345.23V50.39C345.23 49.92 345.2 49.17 345.13 48.14C345.06 47.11 344.86 45.98 344.53 44.74V44.75Z' />
                      </g>
                    </svg>
                  </button>
                )}

                {!isHome && (
                  <Link
                    to={"/"}
                    className='c-header__brand-link u-tap-size  js-hideNav'
                    title='CreateThrive'
                  >
                    <svg
                      className='c-header__logo'
                      width={230}
                      height={55}
                      viewBox='0 0 444 92'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g className='c-logo__iso' fill='#313ba6'>
                        <path d='M0 0V91.47H91.47V0H0ZM32.67 13.07H45.74V26.14H32.67V13.07ZM32.67 39.2H19.6V26.13H32.67V39.2ZM58.8 65.33H45.73V78.4H32.66V65.33H45.73V52.26H58.8V65.33V65.33ZM58.8 39.2H45.73V52.27H32.66V39.2H45.73V26.13H58.8V39.2ZM71.87 52.27H58.8V39.2H71.87V52.27Z' />
                        <path d='M228.57 42.35C227.58 40.47 226.11 39.14 224.18 38.38C222.25 37.61 220.03 37.23 217.54 37.23C214.14 37.23 211.46 37.96 209.49 39.43C207.52 40.89 206.18 42.79 205.47 45.13L211.9 47.17C212.35 45.88 213.14 44.98 214.28 44.48C215.41 43.97 216.5 43.72 217.55 43.72C219.43 43.72 220.77 44.11 221.57 44.9C222.2 45.51 222.57 46.41 222.71 47.57C221.51 47.75 220.37 47.92 219.3 48.09C217.47 48.35 215.83 48.63 214.39 48.93C212.94 49.23 211.7 49.57 210.65 49.95C209.13 50.51 207.92 51.2 207.02 52.04C206.13 52.86 205.49 53.81 205.09 54.89C204.69 55.97 204.49 57.16 204.49 58.47C204.49 60.04 204.85 61.48 205.56 62.78C206.29 64.07 207.36 65.11 208.75 65.89C210.16 66.66 211.88 67.04 213.9 67.04C216.34 67.04 218.35 66.62 219.94 65.79C221.21 65.12 222.4 64.1 223.52 62.78V66.26H229.74V49.01C229.74 47.72 229.69 46.55 229.58 45.51C229.47 44.46 229.14 43.42 228.59 42.37L228.57 42.35ZM221.93 57.66C221.74 58.06 221.37 58.56 220.83 59.15C220.31 59.74 219.59 60.27 218.69 60.74C217.8 61.19 216.7 61.42 215.4 61.42C214.58 61.42 213.87 61.3 213.28 61.05C212.69 60.81 212.23 60.46 211.89 60C211.58 59.53 211.42 58.97 211.42 58.33C211.42 57.86 211.52 57.43 211.71 57.05C211.92 56.67 212.23 56.32 212.65 56C213.08 55.67 213.65 55.36 214.35 55.09C214.96 54.86 215.67 54.65 216.47 54.46C217.27 54.27 218.33 54.06 219.63 53.83C220.47 53.67 221.48 53.49 222.64 53.3C222.62 53.8 222.6 54.35 222.55 54.98C222.5 55.99 222.29 56.88 221.92 57.65L221.93 57.66Z' />
                        <path d='M245.29 30.17H238.18V38.01H233.37V43.5H238.18V54.27C238.18 55.96 238.2 57.48 238.23 58.82C238.26 60.14 238.63 61.45 239.33 62.74C240.1 64.12 241.21 65.12 242.67 65.75C244.15 66.36 245.82 66.68 247.69 66.72C249.57 66.77 251.49 66.62 253.44 66.25V60.34C251.77 60.57 250.24 60.64 248.87 60.55C247.49 60.46 246.49 59.92 245.86 58.93C245.53 58.41 245.35 57.71 245.31 56.84C245.29 55.95 245.28 54.96 245.28 53.86V43.51H253.43V38.02H245.28V30.18L245.29 30.17Z' />
                        <path d='M124.89 36.1C126.49 35.04 128.49 34.51 130.88 34.51C133.49 34.51 135.63 35.15 137.28 36.42C138.93 37.67 140.11 39.44 140.81 41.73L147.97 39.74C146.77 36.08 144.7 33.18 141.75 31.04C138.82 28.9 135.2 27.83 130.88 27.83C127.12 27.83 123.87 28.65 121.13 30.29C118.41 31.93 116.31 34.22 114.83 37.16C113.37 40.1 112.63 43.53 112.63 47.43C112.63 51.33 113.36 54.76 114.83 57.7C116.31 60.64 118.41 62.94 121.13 64.57C123.86 66.21 127.11 67.03 130.88 67.03C135.2 67.03 138.82 65.96 141.75 63.82C144.7 61.68 146.77 58.78 147.97 55.12L140.81 53.13C140.11 55.39 138.94 57.16 137.28 58.44C135.62 59.71 133.49 60.35 130.88 60.35C128.49 60.35 126.5 59.82 124.89 58.76C123.31 57.7 122.11 56.2 121.31 54.27C120.51 52.34 120.11 50.06 120.11 47.45C120.11 44.84 120.51 42.56 121.31 40.63C122.11 38.7 123.31 37.2 124.89 36.14V36.1Z' />
                        <path d='M167.73 37.96C166.88 38.01 166.05 38.16 165.25 38.4C164.45 38.63 163.71 38.95 163.03 39.37C162.14 39.89 161.39 40.55 160.78 41.36C160.49 41.74 160.23 42.15 160 42.57V38.02H153.73V66.24H160.89V51.81C160.89 50.73 161.04 49.76 161.33 48.91C161.63 48.04 162.06 47.29 162.64 46.66C163.22 46.02 163.92 45.5 164.76 45.12C165.6 44.72 166.53 44.48 167.56 44.41C168.6 44.32 169.52 44.41 170.3 44.65V38.01C169.45 37.91 168.59 37.89 167.74 37.96H167.73Z' />
                        <path d='M195 39.32C192.93 37.93 190.41 37.23 187.45 37.23C184.66 37.23 182.2 37.87 180.05 39.14C177.91 40.39 176.23 42.16 175.01 44.44C173.79 46.72 173.18 49.41 173.18 52.49C173.18 55.31 173.8 57.82 175.04 60.02C176.29 62.2 178.02 63.91 180.21 65.17C182.42 66.41 184.97 67.03 187.87 67.03C190.59 67.03 193.09 66.32 195.37 64.89C197.67 63.46 199.35 61.46 200.41 58.88L193.3 56.84C192.78 57.99 192.02 58.87 191.03 59.48C190.04 60.09 188.84 60.39 187.45 60.39C185.24 60.39 183.56 59.68 182.41 58.25C181.58 57.19 181.06 55.81 180.84 54.12H200.65C200.95 50.71 200.6 47.73 199.61 45.21C198.62 42.68 197.08 40.72 195.01 39.33L195 39.32ZM187.76 43.4C189.83 43.4 191.32 44.04 192.23 45.31C192.84 46.13 193.24 47.31 193.45 48.84H181.02C181.3 47.54 181.75 46.46 182.4 45.63C183.55 44.15 185.34 43.41 187.76 43.41V43.4Z' />
                        <path d='M381.19 27.82H374.08V34.09H381.19V27.82Z' />
                        <path d='M381.19 38.01H374.08V66.23H381.19V38.01Z' />
                        <path d='M278.46 39.32C276.39 37.93 273.87 37.23 270.91 37.23C268.12 37.23 265.66 37.87 263.52 39.14C261.38 40.39 259.69 42.16 258.48 44.44C257.26 46.72 256.65 49.41 256.65 52.49C256.65 55.31 257.27 57.82 258.51 60.02C259.76 62.2 261.49 63.91 263.68 65.17C265.89 66.41 268.44 67.03 271.34 67.03C274.06 67.03 276.56 66.32 278.84 64.89C281.14 63.46 282.82 61.46 283.88 58.88L276.77 56.84C276.25 57.99 275.49 58.87 274.5 59.48C273.51 60.09 272.31 60.39 270.92 60.39C268.71 60.39 267.03 59.68 265.88 58.25C265.05 57.19 264.53 55.81 264.31 54.12H284.12C284.42 50.71 284.07 47.73 283.07 45.21C282.08 42.68 280.54 40.72 278.47 39.33L278.46 39.32ZM271.22 43.4C273.29 43.4 274.78 44.04 275.69 45.31C276.3 46.13 276.7 47.31 276.91 48.84H264.48C264.76 47.54 265.21 46.46 265.86 45.63C267.01 44.15 268.79 43.41 271.22 43.41V43.4Z' />
                        <path d='M440.18 54.11H443.6C443.9 50.7 443.55 47.72 442.55 45.2C441.56 42.67 440.02 40.71 437.95 39.32C435.88 37.93 433.36 37.23 430.4 37.23C427.61 37.23 425.15 37.87 423.01 39.14C420.87 40.39 419.19 42.16 417.97 44.44C416.75 46.72 416.14 49.41 416.14 52.49C416.14 55.31 416.76 57.82 418 60.02C419.26 62.2 420.98 63.91 423.17 65.17C425.38 66.41 427.93 67.03 430.83 67.03C433.55 67.03 436.05 66.32 438.33 64.89C440.63 63.46 442.31 61.46 443.37 58.88L436.26 56.84C435.74 57.99 434.98 58.87 433.99 59.48C433 60.09 431.8 60.39 430.41 60.39C428.2 60.39 426.52 59.68 425.37 58.25C424.54 57.19 424.02 55.81 423.8 54.12H440.19L440.18 54.11ZM430.72 43.4C432.79 43.4 434.28 44.04 435.19 45.31C435.8 46.13 436.2 47.31 436.41 48.84H423.98C424.26 47.54 424.71 46.46 425.36 45.63C426.51 44.15 428.29 43.41 430.72 43.41V43.4Z' />
                        <path d='M400.21 57.56L393.52 38.01H386.41L396.65 66.23H403.76L414.01 38.01H406.9L400.21 57.56Z' />
                        <path d='M283.75 35.24H295.62V66.23H302.73V35.24H314.59V28.6H283.75V35.24Z' />
                        <path d='M366.03 37.96C365.18 38.01 364.35 38.16 363.55 38.4C362.75 38.63 362.01 38.95 361.33 39.37C360.44 39.89 359.69 40.55 359.08 41.36C358.79 41.74 358.53 42.15 358.3 42.57V38.02H352.03V66.24H359.19V51.81C359.19 50.73 359.34 49.76 359.63 48.91C359.93 48.04 360.36 47.29 360.94 46.66C361.52 46.02 362.22 45.5 363.06 45.12C363.9 44.72 364.83 44.48 365.86 44.41C366.9 44.32 367.82 44.41 368.6 44.65V38.01C367.75 37.91 366.89 37.89 366.04 37.96H366.03Z' />
                        <path d='M344.53 44.75C344.2 43.5 343.64 42.29 342.86 41.14C342.09 39.99 341.01 39.04 339.62 38.29C338.23 37.54 336.42 37.17 334.21 37.17C331.44 37.17 329.14 37.77 327.31 38.97C326.46 39.54 325.71 40.19 325.06 40.92V28.59H318.74V66.22H325.95V51.9C325.95 50.14 326.14 48.73 326.53 47.67C326.93 46.61 327.44 45.81 328.05 45.27C328.66 44.71 329.31 44.34 330.01 44.15C330.71 43.96 331.36 43.86 331.97 43.86C333.49 43.86 334.65 44.2 335.47 44.88C336.31 45.56 336.91 46.4 337.27 47.41C337.64 48.42 337.85 49.43 337.92 50.44C337.99 51.43 338.02 52.25 338.02 52.9V66.23H345.23V50.39C345.23 49.92 345.2 49.17 345.13 48.14C345.06 47.11 344.86 45.98 344.53 44.74V44.75Z' />
                      </g>
                    </svg>
                  </Link>
                )}
              </h1>
            ) : (
              <div className='c-header__brand'>
                <span className='u-hidden-for-seo'>CreateThrive</span>

                {isHome && (
                  <button
                    className='c-header__brand-link u-tap-size'
                    title='CreateThrive'
                  >
                    <svg
                      width={230}
                      height={55}
                      viewBox='0 0 230 55'
                      xmlns='http://www.w3.org/2000/svg'
                      className='c-header__logo'
                    >
                      <g className='c-logo__iso' fill='#ffffff'>
                        <path d='M0 0V91.47H91.47V0H0ZM32.67 13.07H45.74V26.14H32.67V13.07ZM32.67 39.2H19.6V26.13H32.67V39.2ZM58.8 65.33H45.73V78.4H32.66V65.33H45.73V52.26H58.8V65.33V65.33ZM58.8 39.2H45.73V52.27H32.66V39.2H45.73V26.13H58.8V39.2ZM71.87 52.27H58.8V39.2H71.87V52.27Z' />
                        <path d='M228.57 42.35C227.58 40.47 226.11 39.14 224.18 38.38C222.25 37.61 220.03 37.23 217.54 37.23C214.14 37.23 211.46 37.96 209.49 39.43C207.52 40.89 206.18 42.79 205.47 45.13L211.9 47.17C212.35 45.88 213.14 44.98 214.28 44.48C215.41 43.97 216.5 43.72 217.55 43.72C219.43 43.72 220.77 44.11 221.57 44.9C222.2 45.51 222.57 46.41 222.71 47.57C221.51 47.75 220.37 47.92 219.3 48.09C217.47 48.35 215.83 48.63 214.39 48.93C212.94 49.23 211.7 49.57 210.65 49.95C209.13 50.51 207.92 51.2 207.02 52.04C206.13 52.86 205.49 53.81 205.09 54.89C204.69 55.97 204.49 57.16 204.49 58.47C204.49 60.04 204.85 61.48 205.56 62.78C206.29 64.07 207.36 65.11 208.75 65.89C210.16 66.66 211.88 67.04 213.9 67.04C216.34 67.04 218.35 66.62 219.94 65.79C221.21 65.12 222.4 64.1 223.52 62.78V66.26H229.74V49.01C229.74 47.72 229.69 46.55 229.58 45.51C229.47 44.46 229.14 43.42 228.59 42.37L228.57 42.35ZM221.93 57.66C221.74 58.06 221.37 58.56 220.83 59.15C220.31 59.74 219.59 60.27 218.69 60.74C217.8 61.19 216.7 61.42 215.4 61.42C214.58 61.42 213.87 61.3 213.28 61.05C212.69 60.81 212.23 60.46 211.89 60C211.58 59.53 211.42 58.97 211.42 58.33C211.42 57.86 211.52 57.43 211.71 57.05C211.92 56.67 212.23 56.32 212.65 56C213.08 55.67 213.65 55.36 214.35 55.09C214.96 54.86 215.67 54.65 216.47 54.46C217.27 54.27 218.33 54.06 219.63 53.83C220.47 53.67 221.48 53.49 222.64 53.3C222.62 53.8 222.6 54.35 222.55 54.98C222.5 55.99 222.29 56.88 221.92 57.65L221.93 57.66Z' />
                        <path d='M245.29 30.17H238.18V38.01H233.37V43.5H238.18V54.27C238.18 55.96 238.2 57.48 238.23 58.82C238.26 60.14 238.63 61.45 239.33 62.74C240.1 64.12 241.21 65.12 242.67 65.75C244.15 66.36 245.82 66.68 247.69 66.72C249.57 66.77 251.49 66.62 253.44 66.25V60.34C251.77 60.57 250.24 60.64 248.87 60.55C247.49 60.46 246.49 59.92 245.86 58.93C245.53 58.41 245.35 57.71 245.31 56.84C245.29 55.95 245.28 54.96 245.28 53.86V43.51H253.43V38.02H245.28V30.18L245.29 30.17Z' />
                        <path d='M124.89 36.1C126.49 35.04 128.49 34.51 130.88 34.51C133.49 34.51 135.63 35.15 137.28 36.42C138.93 37.67 140.11 39.44 140.81 41.73L147.97 39.74C146.77 36.08 144.7 33.18 141.75 31.04C138.82 28.9 135.2 27.83 130.88 27.83C127.12 27.83 123.87 28.65 121.13 30.29C118.41 31.93 116.31 34.22 114.83 37.16C113.37 40.1 112.63 43.53 112.63 47.43C112.63 51.33 113.36 54.76 114.83 57.7C116.31 60.64 118.41 62.94 121.13 64.57C123.86 66.21 127.11 67.03 130.88 67.03C135.2 67.03 138.82 65.96 141.75 63.82C144.7 61.68 146.77 58.78 147.97 55.12L140.81 53.13C140.11 55.39 138.94 57.16 137.28 58.44C135.62 59.71 133.49 60.35 130.88 60.35C128.49 60.35 126.5 59.82 124.89 58.76C123.31 57.7 122.11 56.2 121.31 54.27C120.51 52.34 120.11 50.06 120.11 47.45C120.11 44.84 120.51 42.56 121.31 40.63C122.11 38.7 123.31 37.2 124.89 36.14V36.1Z' />
                        <path d='M167.73 37.96C166.88 38.01 166.05 38.16 165.25 38.4C164.45 38.63 163.71 38.95 163.03 39.37C162.14 39.89 161.39 40.55 160.78 41.36C160.49 41.74 160.23 42.15 160 42.57V38.02H153.73V66.24H160.89V51.81C160.89 50.73 161.04 49.76 161.33 48.91C161.63 48.04 162.06 47.29 162.64 46.66C163.22 46.02 163.92 45.5 164.76 45.12C165.6 44.72 166.53 44.48 167.56 44.41C168.6 44.32 169.52 44.41 170.3 44.65V38.01C169.45 37.91 168.59 37.89 167.74 37.96H167.73Z' />
                        <path d='M195 39.32C192.93 37.93 190.41 37.23 187.45 37.23C184.66 37.23 182.2 37.87 180.05 39.14C177.91 40.39 176.23 42.16 175.01 44.44C173.79 46.72 173.18 49.41 173.18 52.49C173.18 55.31 173.8 57.82 175.04 60.02C176.29 62.2 178.02 63.91 180.21 65.17C182.42 66.41 184.97 67.03 187.87 67.03C190.59 67.03 193.09 66.32 195.37 64.89C197.67 63.46 199.35 61.46 200.41 58.88L193.3 56.84C192.78 57.99 192.02 58.87 191.03 59.48C190.04 60.09 188.84 60.39 187.45 60.39C185.24 60.39 183.56 59.68 182.41 58.25C181.58 57.19 181.06 55.81 180.84 54.12H200.65C200.95 50.71 200.6 47.73 199.61 45.21C198.62 42.68 197.08 40.72 195.01 39.33L195 39.32ZM187.76 43.4C189.83 43.4 191.32 44.04 192.23 45.31C192.84 46.13 193.24 47.31 193.45 48.84H181.02C181.3 47.54 181.75 46.46 182.4 45.63C183.55 44.15 185.34 43.41 187.76 43.41V43.4Z' />
                        <path d='M381.19 27.82H374.08V34.09H381.19V27.82Z' />
                        <path d='M381.19 38.01H374.08V66.23H381.19V38.01Z' />
                        <path d='M278.46 39.32C276.39 37.93 273.87 37.23 270.91 37.23C268.12 37.23 265.66 37.87 263.52 39.14C261.38 40.39 259.69 42.16 258.48 44.44C257.26 46.72 256.65 49.41 256.65 52.49C256.65 55.31 257.27 57.82 258.51 60.02C259.76 62.2 261.49 63.91 263.68 65.17C265.89 66.41 268.44 67.03 271.34 67.03C274.06 67.03 276.56 66.32 278.84 64.89C281.14 63.46 282.82 61.46 283.88 58.88L276.77 56.84C276.25 57.99 275.49 58.87 274.5 59.48C273.51 60.09 272.31 60.39 270.92 60.39C268.71 60.39 267.03 59.68 265.88 58.25C265.05 57.19 264.53 55.81 264.31 54.12H284.12C284.42 50.71 284.07 47.73 283.07 45.21C282.08 42.68 280.54 40.72 278.47 39.33L278.46 39.32ZM271.22 43.4C273.29 43.4 274.78 44.04 275.69 45.31C276.3 46.13 276.7 47.31 276.91 48.84H264.48C264.76 47.54 265.21 46.46 265.86 45.63C267.01 44.15 268.79 43.41 271.22 43.41V43.4Z' />
                        <path d='M440.18 54.11H443.6C443.9 50.7 443.55 47.72 442.55 45.2C441.56 42.67 440.02 40.71 437.95 39.32C435.88 37.93 433.36 37.23 430.4 37.23C427.61 37.23 425.15 37.87 423.01 39.14C420.87 40.39 419.19 42.16 417.97 44.44C416.75 46.72 416.14 49.41 416.14 52.49C416.14 55.31 416.76 57.82 418 60.02C419.26 62.2 420.98 63.91 423.17 65.17C425.38 66.41 427.93 67.03 430.83 67.03C433.55 67.03 436.05 66.32 438.33 64.89C440.63 63.46 442.31 61.46 443.37 58.88L436.26 56.84C435.74 57.99 434.98 58.87 433.99 59.48C433 60.09 431.8 60.39 430.41 60.39C428.2 60.39 426.52 59.68 425.37 58.25C424.54 57.19 424.02 55.81 423.8 54.12H440.19L440.18 54.11ZM430.72 43.4C432.79 43.4 434.28 44.04 435.19 45.31C435.8 46.13 436.2 47.31 436.41 48.84H423.98C424.26 47.54 424.71 46.46 425.36 45.63C426.51 44.15 428.29 43.41 430.72 43.41V43.4Z' />
                        <path d='M400.21 57.56L393.52 38.01H386.41L396.65 66.23H403.76L414.01 38.01H406.9L400.21 57.56Z' />
                        <path d='M283.75 35.24H295.62V66.23H302.73V35.24H314.59V28.6H283.75V35.24Z' />
                        <path d='M366.03 37.96C365.18 38.01 364.35 38.16 363.55 38.4C362.75 38.63 362.01 38.95 361.33 39.37C360.44 39.89 359.69 40.55 359.08 41.36C358.79 41.74 358.53 42.15 358.3 42.57V38.02H352.03V66.24H359.19V51.81C359.19 50.73 359.34 49.76 359.63 48.91C359.93 48.04 360.36 47.29 360.94 46.66C361.52 46.02 362.22 45.5 363.06 45.12C363.9 44.72 364.83 44.48 365.86 44.41C366.9 44.32 367.82 44.41 368.6 44.65V38.01C367.75 37.91 366.89 37.89 366.04 37.96H366.03Z' />
                        <path d='M344.53 44.75C344.2 43.5 343.64 42.29 342.86 41.14C342.09 39.99 341.01 39.04 339.62 38.29C338.23 37.54 336.42 37.17 334.21 37.17C331.44 37.17 329.14 37.77 327.31 38.97C326.46 39.54 325.71 40.19 325.06 40.92V28.59H318.74V66.22H325.95V51.9C325.95 50.14 326.14 48.73 326.53 47.67C326.93 46.61 327.44 45.81 328.05 45.27C328.66 44.71 329.31 44.34 330.01 44.15C330.71 43.96 331.36 43.86 331.97 43.86C333.49 43.86 334.65 44.2 335.47 44.88C336.31 45.56 336.91 46.4 337.27 47.41C337.64 48.42 337.85 49.43 337.92 50.44C337.99 51.43 338.02 52.25 338.02 52.9V66.23H345.23V50.39C345.23 49.92 345.2 49.17 345.13 48.14C345.06 47.11 344.86 45.98 344.53 44.74V44.75Z' />
                      </g>
                    </svg>
                  </button>
                )}

                {!isHome && (
                  <Link
                    to={"/"}
                    className='c-header__brand-link u-tap-size  js-hideNav'
                    title='CreateThrive'
                  >
                    <svg
                      className='c-header__logo'
                      width={230}
                      height={55}
                      viewBox='0 0 444 92'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g className='c-logo__iso' fill='#313ba6'>
                        <path d='M0 0V91.47H91.47V0H0ZM32.67 13.07H45.74V26.14H32.67V13.07ZM32.67 39.2H19.6V26.13H32.67V39.2ZM58.8 65.33H45.73V78.4H32.66V65.33H45.73V52.26H58.8V65.33V65.33ZM58.8 39.2H45.73V52.27H32.66V39.2H45.73V26.13H58.8V39.2ZM71.87 52.27H58.8V39.2H71.87V52.27Z' />
                        <path d='M228.57 42.35C227.58 40.47 226.11 39.14 224.18 38.38C222.25 37.61 220.03 37.23 217.54 37.23C214.14 37.23 211.46 37.96 209.49 39.43C207.52 40.89 206.18 42.79 205.47 45.13L211.9 47.17C212.35 45.88 213.14 44.98 214.28 44.48C215.41 43.97 216.5 43.72 217.55 43.72C219.43 43.72 220.77 44.11 221.57 44.9C222.2 45.51 222.57 46.41 222.71 47.57C221.51 47.75 220.37 47.92 219.3 48.09C217.47 48.35 215.83 48.63 214.39 48.93C212.94 49.23 211.7 49.57 210.65 49.95C209.13 50.51 207.92 51.2 207.02 52.04C206.13 52.86 205.49 53.81 205.09 54.89C204.69 55.97 204.49 57.16 204.49 58.47C204.49 60.04 204.85 61.48 205.56 62.78C206.29 64.07 207.36 65.11 208.75 65.89C210.16 66.66 211.88 67.04 213.9 67.04C216.34 67.04 218.35 66.62 219.94 65.79C221.21 65.12 222.4 64.1 223.52 62.78V66.26H229.74V49.01C229.74 47.72 229.69 46.55 229.58 45.51C229.47 44.46 229.14 43.42 228.59 42.37L228.57 42.35ZM221.93 57.66C221.74 58.06 221.37 58.56 220.83 59.15C220.31 59.74 219.59 60.27 218.69 60.74C217.8 61.19 216.7 61.42 215.4 61.42C214.58 61.42 213.87 61.3 213.28 61.05C212.69 60.81 212.23 60.46 211.89 60C211.58 59.53 211.42 58.97 211.42 58.33C211.42 57.86 211.52 57.43 211.71 57.05C211.92 56.67 212.23 56.32 212.65 56C213.08 55.67 213.65 55.36 214.35 55.09C214.96 54.86 215.67 54.65 216.47 54.46C217.27 54.27 218.33 54.06 219.63 53.83C220.47 53.67 221.48 53.49 222.64 53.3C222.62 53.8 222.6 54.35 222.55 54.98C222.5 55.99 222.29 56.88 221.92 57.65L221.93 57.66Z' />
                        <path d='M245.29 30.17H238.18V38.01H233.37V43.5H238.18V54.27C238.18 55.96 238.2 57.48 238.23 58.82C238.26 60.14 238.63 61.45 239.33 62.74C240.1 64.12 241.21 65.12 242.67 65.75C244.15 66.36 245.82 66.68 247.69 66.72C249.57 66.77 251.49 66.62 253.44 66.25V60.34C251.77 60.57 250.24 60.64 248.87 60.55C247.49 60.46 246.49 59.92 245.86 58.93C245.53 58.41 245.35 57.71 245.31 56.84C245.29 55.95 245.28 54.96 245.28 53.86V43.51H253.43V38.02H245.28V30.18L245.29 30.17Z' />
                        <path d='M124.89 36.1C126.49 35.04 128.49 34.51 130.88 34.51C133.49 34.51 135.63 35.15 137.28 36.42C138.93 37.67 140.11 39.44 140.81 41.73L147.97 39.74C146.77 36.08 144.7 33.18 141.75 31.04C138.82 28.9 135.2 27.83 130.88 27.83C127.12 27.83 123.87 28.65 121.13 30.29C118.41 31.93 116.31 34.22 114.83 37.16C113.37 40.1 112.63 43.53 112.63 47.43C112.63 51.33 113.36 54.76 114.83 57.7C116.31 60.64 118.41 62.94 121.13 64.57C123.86 66.21 127.11 67.03 130.88 67.03C135.2 67.03 138.82 65.96 141.75 63.82C144.7 61.68 146.77 58.78 147.97 55.12L140.81 53.13C140.11 55.39 138.94 57.16 137.28 58.44C135.62 59.71 133.49 60.35 130.88 60.35C128.49 60.35 126.5 59.82 124.89 58.76C123.31 57.7 122.11 56.2 121.31 54.27C120.51 52.34 120.11 50.06 120.11 47.45C120.11 44.84 120.51 42.56 121.31 40.63C122.11 38.7 123.31 37.2 124.89 36.14V36.1Z' />
                        <path d='M167.73 37.96C166.88 38.01 166.05 38.16 165.25 38.4C164.45 38.63 163.71 38.95 163.03 39.37C162.14 39.89 161.39 40.55 160.78 41.36C160.49 41.74 160.23 42.15 160 42.57V38.02H153.73V66.24H160.89V51.81C160.89 50.73 161.04 49.76 161.33 48.91C161.63 48.04 162.06 47.29 162.64 46.66C163.22 46.02 163.92 45.5 164.76 45.12C165.6 44.72 166.53 44.48 167.56 44.41C168.6 44.32 169.52 44.41 170.3 44.65V38.01C169.45 37.91 168.59 37.89 167.74 37.96H167.73Z' />
                        <path d='M195 39.32C192.93 37.93 190.41 37.23 187.45 37.23C184.66 37.23 182.2 37.87 180.05 39.14C177.91 40.39 176.23 42.16 175.01 44.44C173.79 46.72 173.18 49.41 173.18 52.49C173.18 55.31 173.8 57.82 175.04 60.02C176.29 62.2 178.02 63.91 180.21 65.17C182.42 66.41 184.97 67.03 187.87 67.03C190.59 67.03 193.09 66.32 195.37 64.89C197.67 63.46 199.35 61.46 200.41 58.88L193.3 56.84C192.78 57.99 192.02 58.87 191.03 59.48C190.04 60.09 188.84 60.39 187.45 60.39C185.24 60.39 183.56 59.68 182.41 58.25C181.58 57.19 181.06 55.81 180.84 54.12H200.65C200.95 50.71 200.6 47.73 199.61 45.21C198.62 42.68 197.08 40.72 195.01 39.33L195 39.32ZM187.76 43.4C189.83 43.4 191.32 44.04 192.23 45.31C192.84 46.13 193.24 47.31 193.45 48.84H181.02C181.3 47.54 181.75 46.46 182.4 45.63C183.55 44.15 185.34 43.41 187.76 43.41V43.4Z' />
                        <path d='M381.19 27.82H374.08V34.09H381.19V27.82Z' />
                        <path d='M381.19 38.01H374.08V66.23H381.19V38.01Z' />
                        <path d='M278.46 39.32C276.39 37.93 273.87 37.23 270.91 37.23C268.12 37.23 265.66 37.87 263.52 39.14C261.38 40.39 259.69 42.16 258.48 44.44C257.26 46.72 256.65 49.41 256.65 52.49C256.65 55.31 257.27 57.82 258.51 60.02C259.76 62.2 261.49 63.91 263.68 65.17C265.89 66.41 268.44 67.03 271.34 67.03C274.06 67.03 276.56 66.32 278.84 64.89C281.14 63.46 282.82 61.46 283.88 58.88L276.77 56.84C276.25 57.99 275.49 58.87 274.5 59.48C273.51 60.09 272.31 60.39 270.92 60.39C268.71 60.39 267.03 59.68 265.88 58.25C265.05 57.19 264.53 55.81 264.31 54.12H284.12C284.42 50.71 284.07 47.73 283.07 45.21C282.08 42.68 280.54 40.72 278.47 39.33L278.46 39.32ZM271.22 43.4C273.29 43.4 274.78 44.04 275.69 45.31C276.3 46.13 276.7 47.31 276.91 48.84H264.48C264.76 47.54 265.21 46.46 265.86 45.63C267.01 44.15 268.79 43.41 271.22 43.41V43.4Z' />
                        <path d='M440.18 54.11H443.6C443.9 50.7 443.55 47.72 442.55 45.2C441.56 42.67 440.02 40.71 437.95 39.32C435.88 37.93 433.36 37.23 430.4 37.23C427.61 37.23 425.15 37.87 423.01 39.14C420.87 40.39 419.19 42.16 417.97 44.44C416.75 46.72 416.14 49.41 416.14 52.49C416.14 55.31 416.76 57.82 418 60.02C419.26 62.2 420.98 63.91 423.17 65.17C425.38 66.41 427.93 67.03 430.83 67.03C433.55 67.03 436.05 66.32 438.33 64.89C440.63 63.46 442.31 61.46 443.37 58.88L436.26 56.84C435.74 57.99 434.98 58.87 433.99 59.48C433 60.09 431.8 60.39 430.41 60.39C428.2 60.39 426.52 59.68 425.37 58.25C424.54 57.19 424.02 55.81 423.8 54.12H440.19L440.18 54.11ZM430.72 43.4C432.79 43.4 434.28 44.04 435.19 45.31C435.8 46.13 436.2 47.31 436.41 48.84H423.98C424.26 47.54 424.71 46.46 425.36 45.63C426.51 44.15 428.29 43.41 430.72 43.41V43.4Z' />
                        <path d='M400.21 57.56L393.52 38.01H386.41L396.65 66.23H403.76L414.01 38.01H406.9L400.21 57.56Z' />
                        <path d='M283.75 35.24H295.62V66.23H302.73V35.24H314.59V28.6H283.75V35.24Z' />
                        <path d='M366.03 37.96C365.18 38.01 364.35 38.16 363.55 38.4C362.75 38.63 362.01 38.95 361.33 39.37C360.44 39.89 359.69 40.55 359.08 41.36C358.79 41.74 358.53 42.15 358.3 42.57V38.02H352.03V66.24H359.19V51.81C359.19 50.73 359.34 49.76 359.63 48.91C359.93 48.04 360.36 47.29 360.94 46.66C361.52 46.02 362.22 45.5 363.06 45.12C363.9 44.72 364.83 44.48 365.86 44.41C366.9 44.32 367.82 44.41 368.6 44.65V38.01C367.75 37.91 366.89 37.89 366.04 37.96H366.03Z' />
                        <path d='M344.53 44.75C344.2 43.5 343.64 42.29 342.86 41.14C342.09 39.99 341.01 39.04 339.62 38.29C338.23 37.54 336.42 37.17 334.21 37.17C331.44 37.17 329.14 37.77 327.31 38.97C326.46 39.54 325.71 40.19 325.06 40.92V28.59H318.74V66.22H325.95V51.9C325.95 50.14 326.14 48.73 326.53 47.67C326.93 46.61 327.44 45.81 328.05 45.27C328.66 44.71 329.31 44.34 330.01 44.15C330.71 43.96 331.36 43.86 331.97 43.86C333.49 43.86 334.65 44.2 335.47 44.88C336.31 45.56 336.91 46.4 337.27 47.41C337.64 48.42 337.85 49.43 337.92 50.44C337.99 51.43 338.02 52.25 338.02 52.9V66.23H345.23V50.39C345.23 49.92 345.2 49.17 345.13 48.14C345.06 47.11 344.86 45.98 344.53 44.74V44.75Z' />
                      </g>
                    </svg>
                  </Link>
                )}
              </div>
            )}
            <button
              type='button'
              title='Navigation button'
              className='c-header__btn u-tap-size js-toggleNav'
            >
              <span className='c-header__btn-bar' />
            </button>
            <nav className='c-header__nav c-nav js-nav'>
              <h2 className='u-hidden-for-seo'>Navigation</h2>
              <div className='c-nav__holder'>
                <ul className='c-nav__list'>
                  <li className='c-nav__item c-nav__delay--1'>
                    <Link
                      to={"/about"}
                      activeClassName='is-active'
                      className='c-nav__link u-tap-size  js-hideNav'
                    >
                      About
                    </Link>
                  </li>
                  <li className='c-nav__item c-nav__delay--2'>
                    <Link
                      to={"/services"}
                      activeClassName='is-active'
                      className='c-nav__link u-tap-size  js-hideNav'
                    >
                      Services
                    </Link>
                  </li>
                  <li className='c-nav__item c-nav__delay--3'>
                    <Link
                      to={"/work"}
                      activeClassName='is-active'
                      className='c-nav__link u-tap-size  js-hideNav'
                    >
                      Work
                    </Link>
                  </li>
                  <li className='c-nav__item c-nav__delay--4'>
                    <Link
                      to={"/blog"}
                      target='_blank'
                      className='c-nav__link u-tap-size'
                    >
                      Blog
                    </Link>
                  </li>
                  <li className='c-nav__item c-nav__delay--5'>
                    <Link
                      to={"https://createthrive.bamboohr.com/jobs/"}
                      target='_blank'
                      className='c-nav__link u-tap-size  js-hideNav'
                    >
                      Careers
                    </Link>
                  </li>

                  {!isContact && (
                    <li className='c-nav__item c-nav__delay--6'>
                      <Link
                        to={"/contact"}
                        className='c-nav__link c-btn c-btn--icon c-btn--cta u-tap-size js-hideNav'
                      >
                        Get in touch<span class='c-btn__icon'></span>
                      </Link>
                    </li>
                  )}
                  {isContact && (
                    <li className='c-nav__item c-nav__delay--6'>
                      <Link
                        to={"/contact"}
                        activeClassName='is-active'
                        className='c-nav__link u-tap-size  js-hideNav'
                      >
                        Get in touch
                      </Link>
                    </li>
                  )}
                </ul>
                <h3 className='c-nav__social-title u-hidden-for-seo'>
                  Social media
                </h3>

                {socials && socials.items.length > 0 && (
                  <ul className='c-nav__social c-social c-social--white c-nav__delay--7'>
                    {socials.items.map(
                      (item, i) =>
                        item.social_link &&
                        item.social_link.url && (
                          <li key={i} className='c-social__item'>
                            <a
                              href={item.social_link.url}
                              target='_blank'
                              rel='noopener noreferrer'
                              className={`c-social__link u-tap-size icon-social-${item.social_icon}`}
                              title={item.social_name}
                            >
                              <span className='u-hidden-for-seo'>
                                {item.social_name}
                              </span>
                            </a>
                          </li>
                        )
                    )}
                  </ul>
                )}
              </div>{" "}
              {/* .c-nav__holder */}
            </nav>{" "}
            {/* .c-nav */}
          </div>{" "}
          {/* .c-header__wrapper */}
        </div>{" "}
        {/* .c-header__holder */}
        <div className='c-header__limit'>
          <Waypoint
            onEnter={() => {
              $header.classList.remove("is-fixed");
            }}
            onLeave={() => {
              $header.classList.add("is-fixed");
            }}
          />
        </div>{" "}
        {/* .c-header__limit */}
      </header>{" "}
      {/* .c-header */}
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
