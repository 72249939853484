import React from "react"
import PropTypes from "prop-types"
import { SliceZone } from '@prismicio/react'
import { components } from '../slices/footer'

const Footer = ({ data, clutch, socials, contactUs }) => {

      return (

            <>
                  {/* FOOTER */}
                  <footer className="c-footer">
                        <div className="c-footer__wrapper o-wrapper o-wrapper--xl">

                              {contactUs &&
                                    <div className="c-footer__col">
                                          <h3 className="c-footer__title u-alt-font--bold">{contactUs.contact_title}</h3>
                                          <ul className="c-footer__contact c-footer-contact">
                                                {contactUs.items.map((item, i) => (
                                                      <li key={i} className="c-footer-contact__item">
                                                            {item.contact_link && item.contact_link.url && <a href={item.contact_link.url} target="_blank" rel="noopener noreferrer">{item.contact_text}</a>}
                                                            {!item.contact_link.url && <>{item.contact_text}</>}
                                                            <span className={`c-footer-contact__icon icon-${item.contact_icon}`} />
                                                      </li>
                                                ))}
                                          </ul>
                                    </div>
                              }

                              {socials && socials.items.length > 0 && 
                                    <div className="c-footer__col">
                                          <h3 className="c-footer__title u-alt-font--bold">{socials.socials_title}</h3>
                                          <ul className="c-footer__social c-social c-social--white">
                                                {socials.items.map((item, i) => (
                                                      item.social_link && item.social_link.url &&
                                                      <li key={i} className="c-social__item">
                                                            <a href={item.social_link.url} target="_blank" rel="noopener noreferrer" className={`c-social__link u-tap-size icon-social-${item.social_icon}`} title={item.social_name}>
                                                                  <span className="u-hidden-for-seo">{item.social_name}</span>
                                                            </a>
                                                      </li>
                                                ))}
                                          </ul> {/* .c-social */}
                                    </div>
                              }

                              <SliceZone slices={data.body} components={components} context={clutch} />

                        </div> {/* .c-footer__wrapper */}
                        <div className="c-footer__bottom">
                              <div className="c-footer__bottom-wrapper u-alt-font o-wrapper o-wrapper--xl">
                                    <p className="c-footer__copyright">{data.disclaimer} {new Date().getFullYear()}</p>
                              </div> {/* .c-footer__wrapper */}
                        </div> {/* .c-footer__bottom */}
                  </footer> {/* .c-footer */}

            </>

      )

}

Footer.propTypes = {
      children: PropTypes.node,
}


export default Footer
