import * as React from 'react'
import { graphql } from 'gatsby'

export const Reviewed = ({ context, slice }) => {

      const $clutch = context;
      
      return (

            <div className="c-footer__col">
                  <h3 className="c-footer__title u-alt-font--bold">{slice.primary.reviewed_title}</h3>
                  {$clutch.clutch_link && $clutch.clutch_link.url && <a href={$clutch.clutch_link.url} target="_blank"  rel="noopener noreferrer" className="c-footer__clutch c-footer-clutch u-tap-size">
                        <img src="../../../assets/images/static/clutch/clutch.svg" width={196} height={57} className="c-footer-clutch__logo" alt="Clutch" title="Clutch" loading="lazy" />
                        <span className="c-footer-clutch__right">
                              <img src="../../../assets/images/static/clutch/clutch-stars.svg" width={150} height={26} className="c-footer-clutch__stars" alt="5 stars" title="5 stars" loading="lazy" />
                              <span className="c-footer-clutch__link">{$clutch.clutch_linktitle}</span>
                        </span> {/* .c-footer-clutch__right */}
                  </a>}
            </div>
      )

}


export const query = graphql`
  fragment PostDataBodyReviewedOn on PrismicFooterDataBodyReviewedOn {
      primary {
            reviewed_title
          }
  }
`