import React from "react"
import PropTypes from "prop-types"
import { withPrefix } from 'gatsby';
import Helmet from "react-helmet"
import Header from "./header";
import CallToAction from "./cta";
import Footer from "./footer";
import { useLocation } from "@reach/router";



// Inline fixed CSS
import "../styles/fixed/settings.vars.css";
import "../styles/fixed/generic.reset.css";
import "../styles/fixed/generic.fonts.css";
import "../styles/fixed/generic.screen.css";
import "../styles/fixed/generic.icons.css";
import "../styles/fixed/objects.structure.css";
import "../styles/fixed/objects.section.css";

import "../styles/fixed/components.header.css";
import "../styles/fixed/components.btns.css";
import "../styles/fixed/components.hero.css";
import "../styles/fixed/components.headings.css";
import "../styles/fixed/components.openings.css";

import "../styles/fixed/utilities.general.css";
import "../styles/fixed/utilities.type.css";


// Pages
import "../styles/sitio/home.hero.css"

import "../styles/sitio/services.hero.css"
import "../styles/sitio/services.services.css"

import "../styles/sitio/about.hero.css"
import "../styles/sitio/about.milestones.css"

import "../styles/sitio/components.filter.css"
import "../styles/sitio/work.cases.css"

import "../styles/sitio/contact.viewport.css"
import "../styles/sitio/contact.hero.css"
import "../styles/sitio/contact.form.css"

import "../styles/sitio/case.hero.css"

import "../styles/sitio/blog.posts.css"
import "../styles/sitio/blogpost.hero.css"


const Layout = ({ children, cta, footer, clutch, socials, contactUs, headerTransparent, notSeoLogo }) => {

  const { pathname } = useLocation();
  let isContact = pathname.indexOf("/contact") !== -1 ? true : false;


  if (typeof window !== 'undefined') {
    //Set gclid
    let url = new URLSearchParams(window.document.location.search.substring(1));
    let utm_campaign = url.get('utm_campaign')
    let utm_source = url.get('utm_source')
    let utm_medium = url.get('utm_medium')

    utm_campaign ? localStorage.setItem('utm_campaign', utm_campaign) : utm_campaign = utm_campaign;
    utm_source ? localStorage.setItem('utm_source', utm_source) : utm_source = utm_source;
    utm_medium ? localStorage.setItem('utm_medium', utm_medium) : utm_medium = utm_medium;

    window.onbeforeunload = function () {
      localStorage.removeItem('utm_campaign');
      localStorage.removeItem('utm_source');
      localStorage.removeItem('utm_medium');
    }
  }

    

  return (

    <>

      <Helmet> 
        
        <link href={withPrefix('../../assets/css/fixed/generic.screen.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/fixed/components.header.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/fixed/components.btns.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/fixed/components.pic.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/fixed/components.cta.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/fixed/components.social.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/fixed/components.footer.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/fixed/utilities.general.css')} rel="stylesheet" type="text/css" />

      </Helmet>


      <Header socials={socials} headerTransparent={headerTransparent} notSeoLogo={notSeoLogo} />

      {children}


      
      {cta && !isContact && <CallToAction data={cta} />}

      <Footer data={footer} clutch={clutch} socials={socials} contactUs={contactUs} />


    </>

  )

}

Layout.propTypes = {
  children: PropTypes.node,
  cta: PropTypes.object,
  footer: PropTypes.object,
  clutch: PropTypes.object,
  socials: PropTypes.object,
  contactUs: PropTypes.object,
  headerTransparent: PropTypes.bool
}

export default Layout
